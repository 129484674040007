import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _42c9f5be = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _66e3105e = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _a05cd348 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _68757882 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _c4be2402 = () => interopDefault(import('../pages/compare/index.vue' /* webpackChunkName: "pages/compare/index" */))
const _7030b5a9 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _fc1bd47a = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _02d717b0 = () => interopDefault(import('../pages/reset-password-again/index.vue' /* webpackChunkName: "pages/reset-password-again/index" */))
const _8284f7da = () => interopDefault(import('../pages/product/catalog/index.vue' /* webpackChunkName: "pages/product/catalog/index" */))
const _c3522ca4 = () => interopDefault(import('../pages/profile/company.vue' /* webpackChunkName: "pages/profile/company" */))
const _320cb848 = () => interopDefault(import('../pages/profile/favorites.vue' /* webpackChunkName: "pages/profile/favorites" */))
const _078664f7 = () => interopDefault(import('../pages/profile/loyalty.vue' /* webpackChunkName: "pages/profile/loyalty" */))
const _57a5f647 = () => interopDefault(import('../pages/profile/orders/index.vue' /* webpackChunkName: "pages/profile/orders/index" */))
const _9b711854 = () => interopDefault(import('../pages/profile/promocodes.vue' /* webpackChunkName: "pages/profile/promocodes" */))
const _6a03f8a2 = () => interopDefault(import('../pages/profile/refunds/index.vue' /* webpackChunkName: "pages/profile/refunds/index" */))
const _0ebea24c = () => interopDefault(import('../pages/profile/reviews.vue' /* webpackChunkName: "pages/profile/reviews" */))
const _598541d0 = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _3b3d4ae1 = () => interopDefault(import('../pages/room/catalog/index.vue' /* webpackChunkName: "pages/room/catalog/index" */))
const _4ecfbdda = () => interopDefault(import('../pages/search/not-found.vue' /* webpackChunkName: "pages/search/not-found" */))
const _0caf384a = () => interopDefault(import('../pages/checkout/success/_order.vue' /* webpackChunkName: "pages/checkout/success/_order" */))
const _b19a35e4 = () => interopDefault(import('../pages/product/catalog/_slug/index.vue' /* webpackChunkName: "pages/product/catalog/_slug/index" */))
const _25f5042d = () => interopDefault(import('../pages/profile/orders/_order/index.vue' /* webpackChunkName: "pages/profile/orders/_order/index" */))
const _6563e098 = () => interopDefault(import('../pages/profile/refunds/_refund.vue' /* webpackChunkName: "pages/profile/refunds/_refund" */))
const _2b89d95c = () => interopDefault(import('../pages/room/catalog/_slug/index.vue' /* webpackChunkName: "pages/room/catalog/_slug/index" */))
const _08c66cad = () => interopDefault(import('../pages/profile/orders/_order/refund.vue' /* webpackChunkName: "pages/profile/orders/_order/refund" */))
const _4772cd19 = () => interopDefault(import('../pages/blog/_slug/index.vue' /* webpackChunkName: "pages/blog/_slug/index" */))
const _c3474590 = () => interopDefault(import('../pages/catalog/_query.vue' /* webpackChunkName: "pages/catalog/_query" */))
const _99d88a8c = () => interopDefault(import('../pages/contacts/_id/index.vue' /* webpackChunkName: "pages/contacts/_id/index" */))
const _7701a7f4 = () => interopDefault(import('../pages/product/_id/index.vue' /* webpackChunkName: "pages/product/_id/index" */))
const _0e8a8c52 = () => interopDefault(import('../pages/room/_slug/index.vue' /* webpackChunkName: "pages/room/_slug/index" */))
const _200ad8e7 = () => interopDefault(import('../pages/search/_query.vue' /* webpackChunkName: "pages/search/_query" */))
const _3d1249a7 = () => interopDefault(import('../pages/set/_id/index.vue' /* webpackChunkName: "pages/set/_id/index" */))
const _6e7a1461 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _42c9f5be,
    name: "auth___pl"
  }, {
    path: "/blog",
    component: _66e3105e,
    name: "blog___pl"
  }, {
    path: "/cart",
    component: _a05cd348,
    name: "cart___pl"
  }, {
    path: "/checkout",
    component: _68757882,
    name: "checkout___pl"
  }, {
    path: "/compare",
    component: _c4be2402,
    name: "compare___pl"
  }, {
    path: "/en",
    component: _7030b5a9,
    name: "index___en"
  }, {
    path: "/profile",
    component: _fc1bd47a,
    name: "profile___pl"
  }, {
    path: "/reset-password-again",
    component: _02d717b0,
    name: "reset-password-again___pl"
  }, {
    path: "/en/auth",
    component: _42c9f5be,
    name: "auth___en"
  }, {
    path: "/en/blog",
    component: _66e3105e,
    name: "blog___en"
  }, {
    path: "/en/cart",
    component: _a05cd348,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _68757882,
    name: "checkout___en"
  }, {
    path: "/en/compare",
    component: _c4be2402,
    name: "compare___en"
  }, {
    path: "/en/profile",
    component: _fc1bd47a,
    name: "profile___en"
  }, {
    path: "/en/reset-password-again",
    component: _02d717b0,
    name: "reset-password-again___en"
  }, {
    path: "/product/catalog",
    component: _8284f7da,
    name: "product-catalog___pl"
  }, {
    path: "/profile/company",
    component: _c3522ca4,
    name: "profile-company___pl"
  }, {
    path: "/profile/favorites",
    component: _320cb848,
    name: "profile-favorites___pl"
  }, {
    path: "/profile/loyalty",
    component: _078664f7,
    name: "profile-loyalty___pl"
  }, {
    path: "/profile/orders",
    component: _57a5f647,
    name: "profile-orders___pl"
  }, {
    path: "/profile/promocodes",
    component: _9b711854,
    name: "profile-promocodes___pl"
  }, {
    path: "/profile/refunds",
    component: _6a03f8a2,
    name: "profile-refunds___pl"
  }, {
    path: "/profile/reviews",
    component: _0ebea24c,
    name: "profile-reviews___pl"
  }, {
    path: "/profile/tickets",
    component: _598541d0,
    name: "profile-tickets___pl"
  }, {
    path: "/room/catalog",
    component: _3b3d4ae1,
    name: "room-catalog___pl"
  }, {
    path: "/search/not-found",
    component: _4ecfbdda,
    name: "search-not-found___pl"
  }, {
    path: "/en/product/catalog",
    component: _8284f7da,
    name: "product-catalog___en"
  }, {
    path: "/en/profile/company",
    component: _c3522ca4,
    name: "profile-company___en"
  }, {
    path: "/en/profile/favorites",
    component: _320cb848,
    name: "profile-favorites___en"
  }, {
    path: "/en/profile/loyalty",
    component: _078664f7,
    name: "profile-loyalty___en"
  }, {
    path: "/en/profile/orders",
    component: _57a5f647,
    name: "profile-orders___en"
  }, {
    path: "/en/profile/promocodes",
    component: _9b711854,
    name: "profile-promocodes___en"
  }, {
    path: "/en/profile/refunds",
    component: _6a03f8a2,
    name: "profile-refunds___en"
  }, {
    path: "/en/profile/reviews",
    component: _0ebea24c,
    name: "profile-reviews___en"
  }, {
    path: "/en/profile/tickets",
    component: _598541d0,
    name: "profile-tickets___en"
  }, {
    path: "/en/room/catalog",
    component: _3b3d4ae1,
    name: "room-catalog___en"
  }, {
    path: "/en/search/not-found",
    component: _4ecfbdda,
    name: "search-not-found___en"
  }, {
    path: "/en/checkout/success/:order?",
    component: _0caf384a,
    name: "checkout-success-order___en"
  }, {
    path: "/en/product/catalog/:slug",
    component: _b19a35e4,
    name: "product-catalog-slug___en"
  }, {
    path: "/en/profile/orders/:order",
    component: _25f5042d,
    name: "profile-orders-order___en"
  }, {
    path: "/en/profile/refunds/:refund",
    component: _6563e098,
    name: "profile-refunds-refund___en"
  }, {
    path: "/en/room/catalog/:slug",
    component: _2b89d95c,
    name: "room-catalog-slug___en"
  }, {
    path: "/en/profile/orders/:order/refund",
    component: _08c66cad,
    name: "profile-orders-order-refund___en"
  }, {
    path: "/checkout/success/:order?",
    component: _0caf384a,
    name: "checkout-success-order___pl"
  }, {
    path: "/en/blog/:slug",
    component: _4772cd19,
    name: "blog-slug___en"
  }, {
    path: "/en/catalog/:query?",
    component: _c3474590,
    name: "catalog-query___en"
  }, {
    path: "/en/contacts/:id",
    component: _99d88a8c,
    name: "contacts-id___en"
  }, {
    path: "/en/product/:id",
    component: _7701a7f4,
    name: "product-id___en"
  }, {
    path: "/en/room/:slug",
    component: _0e8a8c52,
    name: "room-slug___en"
  }, {
    path: "/en/search/:query?",
    component: _200ad8e7,
    name: "search-query___en"
  }, {
    path: "/en/set/:id",
    component: _3d1249a7,
    name: "set-id___en"
  }, {
    path: "/product/catalog/:slug",
    component: _b19a35e4,
    name: "product-catalog-slug___pl"
  }, {
    path: "/profile/orders/:order",
    component: _25f5042d,
    name: "profile-orders-order___pl"
  }, {
    path: "/profile/refunds/:refund",
    component: _6563e098,
    name: "profile-refunds-refund___pl"
  }, {
    path: "/room/catalog/:slug",
    component: _2b89d95c,
    name: "room-catalog-slug___pl"
  }, {
    path: "/profile/orders/:order/refund",
    component: _08c66cad,
    name: "profile-orders-order-refund___pl"
  }, {
    path: "/blog/:slug",
    component: _4772cd19,
    name: "blog-slug___pl"
  }, {
    path: "/catalog/:query?",
    component: _c3474590,
    name: "catalog-query___pl"
  }, {
    path: "/contacts/:id",
    component: _99d88a8c,
    name: "contacts-id___pl"
  }, {
    path: "/en/:slug",
    component: _6e7a1461,
    name: "slug___en"
  }, {
    path: "/product/:id",
    component: _7701a7f4,
    name: "product-id___pl"
  }, {
    path: "/room/:slug",
    component: _0e8a8c52,
    name: "room-slug___pl"
  }, {
    path: "/search/:query?",
    component: _200ad8e7,
    name: "search-query___pl"
  }, {
    path: "/set/:id",
    component: _3d1249a7,
    name: "set-id___pl"
  }, {
    path: "/",
    component: _7030b5a9,
    name: "index___pl"
  }, {
    path: "/:slug",
    component: _6e7a1461,
    name: "slug___pl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
